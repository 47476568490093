/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:71fe6223-5007-485c-b371-e13e8ba7fefb",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_PLT2Zp8sC",
    "aws_user_pools_web_client_id": "49dan4lu7diihd0slborb61k6u",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "ApiGW",
            "endpoint": "https://u6nd7krz2m.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "unit-assets-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "leasing-journey-20230628133502-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://leasing-journey-20230628133502-hostingbucket-dev.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;
